export const environment = {
  production : false,
  hostUrl : 'http://localhost:8100/',
  envName : 'dev',
  firebaseConfig : {
    apiKey: 'AIzaSyCEocL3ubYqmlk-CFiAi71Y5r5GElH6jnY',
    authDomain: 'devportfoliowebsite.firebaseapp.com',
    projectId: 'devportfoliowebsite',
    storageBucket: 'devportfoliowebsite.appspot.com',
    messagingSenderId: '113915178274',
    appId: '1:113915178274:web:4a144b5923d28309e3c97b',
    measurementId: 'G-M04XCDY6L8'
  },
};
